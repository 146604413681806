/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
 .navbar {
    background: transparent !important;
    z-index: 999;
    box-shadow: none;
    padding-right: 1em;
    padding-top: 1em;
    transition: all .3s ease-in;
    height: 60px;
    padding-top: 0;
}

.navbar.active {
    background: #000 !important;
}

.swmcLogo {
    width: 200px;
}
 
.navbarContainer {
    justify-content: center !important;
}
@media only screen and (max-width: 767.7px) {
    .navbarContainer {
        justify-content: center !important;
    }
  
}
@media only screen and (min-width: 992px) {
    .navbar {
        padding-top: 5px;
    }
}
@media only screen and (max-width: 991.7px) {
    .navbarTransition {
        transform: translateY(-100%);
        transition: all .3s ease-in;
    }
}