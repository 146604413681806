.banner{
    color: #FFFFFF;
    padding-top: 6rem;
}
.heading span {
    color: #FF7A00;
}
@media only screen and (min-width: 992px) {
    .banner{
        background-image: url("https://d2b7dijo04ypct.cloudfront.net/renoFinancing/bannerbg-desk.webp");
        background-size: 100% 100%;
        padding-bottom: 4rem;
    }
    .heading h1{
        font-size: 70px;
        font-weight: 700;
        font-family: Poppins;
    }
  }
@media only screen and (max-width: 991px) {
.banner{
    background-image: url("https://d2b7dijo04ypct.cloudfront.net/renoFinancing/bannerbg-mb.webp");
    background-size: 100% 100%;
    padding-bottom: 1.5rem;
}
.heading h1{
    font-size: 26px;
    font-weight: 700;
    font-family: Poppins;
}
.banner-para h4{
    font-size: 16px;
    font-family: Poppins;
}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .heading h1{
        font-size: 40px;
    }
    .banner-para h4{
        font-size: 20px!important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .heading h1{
        font-size: 43px;
    }
    .banner-para h4{
        font-size: 25px!important;
    }
    .banner-para{
        width: 550px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .banner-para{
        width: 800px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    }
}
@media only screen and (min-width: 1400px) {
    .banner-para{
        width: 800px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
    }
    .heading-text{
        margin-top: 2rem;
    }
}
