.lastpara{
    font-family: "Poppins";
    color: rgb(17, 17, 17);
    text-align: justify;
    margin-bottom: -4rem;
    margin-top: -1.5rem;
    padding:0 3rem 3rem 3rem;
}
.borrtoolrow .btn-lg {
    text-decoration: none !important;
    background-color: #083f88;
    border-color: #083f88;
    color: #ffffff;
}
.name-icon-new {
    position: absolute;
    transform: translateY(-140%);
    width: 20px; 
    margin-left: 1.75rem;
    height: 20px;
    background-image: url('https://d2b7dijo04ypct.cloudfront.net/renovation-LO/nameicon.webp'); 
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none; 
}
.phone-icon-new {
    position: absolute;
    transform: translateY(-140%);
    width: 20px; 
    margin-left: 1.75rem;
    height: 20px;
    background-image: url('https://d2b7dijo04ypct.cloudfront.net/renovation-LO/phoneicon.webp'); 
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none; 
}
.email-icon-new {
    position: absolute;
    transform: translateY(-140%);
    width: 20px; 
    margin-left: 1.75rem;
    height: 20px;
    background-image: url('https://d2b7dijo04ypct.cloudfront.net/renovation-LO/emailicon.webp'); 
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none; 
}
.message-icon-new {
    position: absolute;
    transform: translateY(-375%);
    width: 20px; 
    margin-left: 1.75rem;
    height: 20px;
    background-image: url('https://d2b7dijo04ypct.cloudfront.net/renovation-LO/messageicon.webp'); 
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none; 
  }
.borrtoolrow{
    font-family: "Poppins";
}
.borrowerLeadGeneration .btn-submit{
font-size: 18px !important;
font-weight: 500 !important;
}
.Loinfo a{
color: rgb(17, 17, 17);
text-decoration: underline !important;
}
.requestpara {
    font-size: 30px !important;
    font-weight: 900;
}
.borrtoolrow p {
    font-size: 13px;
}
.borrtoolrow a {
    color: rgb(17, 17, 17);
    text-decoration: underline !important;
}
.Loinfo a:hover{
    color: rgb(17, 17, 17);
    text-decoration:underline;
    }
.borrleadtoolcol{
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2); ;

}
.applyNowBtn{
    background-color: rgba(243, 90, 30, 1);
    border-color: rgba(243, 90, 30, 1);
}
.applyNowBtn:hover{
    background-color: rgba(243, 90, 30, 1);
    border-color: rgba(243, 90, 30, 1);
}
   #state{
    display: none;
}    
.borrowerLeadGeneration .btn-submit{
    background-color: rgba(243, 90, 30, 1);
    border-color: rgba(243, 90, 30, 1);
}
.hrline{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-top: 2px solid #000;
}
.Loinfo h5{
    color: #F35A1E;
    font-weight: 600;
}
.Loinfo p{
    color: #3F4042;
 
}
.Loinfo{
    font-weight: 500;
}
.yourjournytext{
    color: #F35A1E;
    font-weight: 500;
}
.christopher-img{
    width: 210px !important;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.christopher-row{
    margin-bottom: 5rem!important;
}
.chris-phone-link{
    color: #000000;
    text-decoration: none;
}
.chris-phone-link:hover{
    color: #000000;
    text-decoration: underline;
}
@media screen and (min-width: 992px){
    .comprehensivetext, .yourjournytext{
     text-align: center;
    }
    .applyNowBtn{
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 22px;
        font-weight: 500;
    }
    .vertical-line {
        width: 3px; /* Adjust the width as needed */
        height: 90%; /* Makes the line as tall as its container */
        background-color: #FF6B00;; /* Line color (black in this example) */
        margin-left: -15px;
    }
    .hrline{
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .yourjournytext{
        margin-bottom: 40px;
    }
    .borrleadtoolcol{
        padding-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 30px;
    }
    .request{
        /* background-color:#3F4042; */
        color: #FF6B00;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 1rem;
        font-size: 30px !important;
        font-weight: 800;
    }
    .borrtoolrow{
        margin-bottom: 3rem!important;
    }
    .applycol{
        padding-top: 1rem!important;
    }
}
@media screen and (max-width: 991.7px){
    .lastpara {
        padding: 0 0 3rem 0; 
    }
    .borrtoolrow {
        font-family: "Poppins";
        padding: 0rem !important;
        margin-top: -1rem;
        margin-bottom: 2rem;
    }
    .hrline{
    display: none;
    }
    .comprehensivetext,.yourjournytext{
        text-align: justify;
    }
    .applyNowBtn{
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 17px;
        padding-bottom: 17px;
        font-size: 25px;
        font-weight: 500;
        margin-top: 18px;
        margin-bottom: 15px;
    }
    .Loinfo{
        text-align: center!important;
        font-size: 18px;
        margin-top: 2rem;
    }
    .Loinfo h5{
        font-size: 22px;
    }
    .lastpara{
        font-size: 15px;
        color: #243873;
        font-weight: 400!important;
        padding-top: 1.5rem;
    }
    .borrleadtoolcol{
        padding-bottom: 30px;
    }
    .borrleadtoolcol{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .request{
        color: #F26F24;
        width: 99%;
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px;
        text-align: center;
        margin-top: 1rem;
        font-size: 20px;
    }
    .blg-container {
        padding: 5px!important;
    }
    .comprehensivetext{
        text-align: center;
    }
   .mobile-img-section{
    margin-bottom: 1.25rem !important;
   }
   .christopher-row{
    margin-bottom: 3rem!important;
   }
   .name-icon-new, .email-icon-new, .phone-icon-new, .message-icon-new {
    margin-left: 1rem;
   }
   .borrtoolrow .form-control {
    padding: 0.375rem 3rem !important;
    border: 0.5px solid #696565;
}
}
@media screen and (min-width: 320px) and (max-width: 350px) {
   .request{
    padding-left: 40px!important;
    padding-right: 40px!important;
   }
}
@media screen and (max-width: 416px) {
    .borrowerLeadGeneration #contunuebutton {
    margin-left: 5rem!important;
    }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
    .borrowerLeadGeneration #contunuebutton {
        margin-left: -8rem!important;
        }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    .borrowerLeadGeneration #contunuebutton {
        margin-left: -20rem!important;
        }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
    .btn-submit, .btn, .btn-primary{
        font-size: 18px!important;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .applyNowBtn{
        width: 70%; 
    }
    .btn-submit, .btn, .btn-primary{
        font-size: 18px!important;
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .lastpara{
    font-size: 10px;
    }
    .vertical-line {
        margin-left: -5px;
    }
    .Loinfo{
        margin-left: -45px;
    }
    .comprehensivetext{
        font-size: 17x;
    }
    .yourjournytext {
     font-size: 19px;
    }
    .Loinfo h5{
        font-size: 19px;
    }
    .comprehensivetext{
        padding-left: 35px;
        padding-right: 35px;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .lastpara{
    font-size: 12px;
    }
    .Loinfo{
        margin-left: -30px;
        margin-top:1rem;
    }
    .christopher-img{
        margin-right: 60px;
    }
    .yourjournytext {
        font-size: 21px;
       }
    .Loinfo h5{
        font-size: 21px;
    }
    .comprehensivetext{
        padding-left: 75px;
        padding-right: 75px;
    }
}
@media screen and (min-width: 1400px)  and (max-width: 1599px){
    .lastpara{
    font-size: 14px;
    }
    .Loinfo{
        margin-left: -50px;
    }
    .christopher-img{
        margin-right: 60px;
    }
    .applyNowBtn{
        margin-left: 70px;
    }
    .yourjournytext {
        font-size: 21px;
       }
    .Loinfo h5{
        font-size: 21px;
        margin-top: 1rem;
    }
    .comprehensivetext{
        padding-left: 9.5rem;
        padding-right: 9.5rem;
    }
}
@media screen and (min-width: 1600px)  {
    .lastpara{
    font-size: 14px;
    }
    .Loinfo{
        margin-left: -30px;
        margin-top:1rem;
    }
    .christopher-img{
        margin-right: 65px;
    }
    .applyNowBtn{
        margin-left: 130px;
    }
    .yourjournytext {
        font-size: 21px;
       }
    .Loinfo h5{
        font-size: 21px;
    }
    .comprehensivetext{
        padding-left: 12rem;
        padding-right: 12rem;
    }
}
.chris-link-phone, .comprehensivetext{
    color: #000000 !important;
}
@media screen and  (max-width: 576px) {
    .Loinfo p{
        font-size: 14px;
    }
}
.applyNowBtn:hover{
    background-color: #2D3155;
    border: none;
}
.form-control:disabled{
    display: none !important;
}
.borrtoolrow .form-control {
    padding: 0.375rem 4rem;
    border: 0.5px solid #696565;
}
.disclaimertext{
    font-family: "Poppins";
    font-size: 11px !important;
    color: #7D7D7D;
}
.borrtoolrow .consentSmall {
    color: rgb(17, 17, 17) !important;
}
.borrtoolrow .consentLink {
    color: rgb(17, 17, 17) !important;
}
.borrtoolrow .consentSmall:hover {
    color: rgb(0, 170, 228) !important;
}
.borrtoolrow .consentLink:hover {
    color: rgb(0, 170, 228) !important;
}
@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
    .disclaimertext {
        font-family: "Poppins";
        font-size: 9px !important;
    }
  }