.main-blue {
  color: #1E4384;
}
.light-blue {
  color: #0BA9E2;
}
.scrollToTop {
  width: 60px!important;
  height: 60px!important;
  fill: transparent!important;
  /* background: transparent url('./images/scrollUpButton.svg')!important; */
  background-repeat: no-repeat;
}
@media only screen and (min-width:320px)and (max-width:389px) {
  .scrollToTop {
    top: 32rem;
    left: 15.3rem;
  }
}
.scrollToTop:hover {
  opacity: .75!important;
}

.scrollToTop:focus {
  outline: none;
}