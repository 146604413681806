.ways-text{
    background-color: #FF541C;
    color: #FFF;
}
.useRenovationLoan{
    padding-top: 3rem;
    color: #083F88;
    padding-bottom: 3rem;
}
.ways-list{
    font-weight: 500;
    font-family: "Poppins";
}
@media only screen and (min-width: 992px) {
    .ways-text{
        border-top-right-radius: 30px;
        padding: 10px 20px 10px 20px;
    }
    .colage-img{
        width: 90%;
        padding-top: 4.5rem;
    }
}
@media only screen and (max-width: 991px) {
    .ways-text{
        border-radius: 10px;
       text-align: center;
       padding: 10px 15px 10px 15px;
       width: 88%;
       margin-left: auto;
       margin-right: auto;
       display: block;
    }
    .ways-list{
        text-align: justify;
        padding-top: 10px;
        padding-right: 20px;
    }
    .colage-img{
        width: 100%;
        padding-top: 2rem;
    }
}
@media only screen and (min-width: 992px) and (max-width:1199px) {
.ways-list{
    font-size: 13px;
}
.ways-text{
    font-size: 25px;
}
.colage-img{
    width: 100%;
    padding-top: 4rem;
}
.ways-list li{
  margin-bottom:0.2rem;
}
}
@media only screen and (min-width: 1200px) and (max-width:1399px) {
    .ways-list{
        font-size: 15px;
        padding-top: 10px;
        margin-left: 10px;
    }
    .ways-text{
        font-size: 29px;
        width: 88%;
    }
    .colage-img{
        width: 100%;
        padding-top: 4rem;
    }
    .ways-list li{
        margin-bottom: 0.5rem;
    }
    }
@media only screen and (min-width: 1400px){
    .ways-list{
        font-size: 18px;
        padding-top: 10px;
        margin-left: 10px;
    }
    .ways-text{
        font-size: 32px;
        width: 90%;
    }
    .colage-img{
        width: 95%;
        padding-top: 4rem;
        margin-left: 2rem;
    }
    .ways-list li{
        margin-bottom: 0.4rem;
    }
    }