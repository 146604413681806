.loan-heading{
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 10px 20px 5px 20px;
    color: #FFF;
    border-radius: 30px;
}
.th-heading{
    background-color: #00AAE4!important;
    color: #FFF!important;
}
tbody, td{
    border-color: #083F88!important;
    font-weight: 600;
}
.table-row td{ 
color: #083F88;
}
.table-row{
    text-align: center;
}
.disclaimer-text{
    color: #7D7D7D;
    margin-bottom: 3rem;
    margin-top: -1rem;
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
tbody, td{
    font-size: 12.5px;
 }
 tbody, th{
    font-size: 20px;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px){
    tbody, td{
        font-size: 15px;
     }
     tbody, th{
        font-size: 24px;
        }
    }
@media only screen and (min-width: 1400px){
    tbody, td{
        font-size: 18px;
        }
    
    tbody, th{
        font-size: 30px;
        }
    }
@media only screen and (max-width: 991px)  {
    tbody, td{
       font-size: 20px;
       padding-top: 1rem!important;
    }
    .disclaimer-text{
       text-align: justify;
       padding-bottom: 20px;
    }
    .loan-heading h5{
        font-size: 17px;
    }
    .loan-heading{
        z-index: 10;
        position: relative;
    }
    .th-heading{
     padding-top: 2rem!important;
    }
    .table-row{
    margin-top: -2.5rem;
    }
}
@media only screen and (min-width: 992px)  {
    .th-heading{
     padding-top: 2rem!important;
    }
    .table-row{
    margin-top: -2.5rem;
    }
    .loan-heading{
        z-index: 10;
        position: relative;
    }
    .disclaimer-text{
        padding-left: 0;
    }
}