 
.before{
    background-color: #FF7A00;
    color: #FFFFFF;
    padding: 10px 20px 7px 20px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-right: 30px;
}
.after{
    background-color: #083F88;
    color: #FFFFFF;
    padding: 10px 30px 7px 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.afterbefore-img{
    margin-top: -2.3rem;
    z-index: -1;
    position: relative;
}
.features-section{
    color: #083F88;
    font-family: Poppins;
}
.features-section span{
    color: #FF541C;
}
.features-text{
    background-color: #083F88;
    border-top-right-radius: 30px;
    width: max-content;
    padding: 4px 50px 1px 30px;
    color: #FFFF;
    margin-top: -1.5rem;
    margin-left: -14px;
    margin-bottom: 2.8rem;
}
.featuresRow2{
    border: 2px solid #083F88;
    border-radius: 10px;
    padding-bottom: 0.5rem;
}
.customize-col, .vision-col{
    margin-top: 4rem;
}
.click-text{
    color: #083F88;
    font-weight: 700;
}
.click-text a{
    color: #083F88;
}
@media only screen and (min-width: 991px)  {
    .featuresRow2{
        padding-right: 30px;
    }
    .after{
        margin-right: 30px;
    }
    .click-text{
        border: 1px solid #083F88;
        padding: 3rem 10px 12px 10px;
        margin-top: -3rem;
        border-radius: 20px;
    }
    .aftbefore-div{
        margin-top: 3rem;
    }
}
@media only screen and (max-width: 991px)  {
    .customize-col, .vision-col{
        margin-top: 1rem;
    }
    .renoGuide-img{
        width:90%;
        padding-top: 2rem;
    }
    .click-text{
        border: 1px solid #083F88;
        padding: 3rem 10px 12px 10px;
        margin-top: -3rem;
        border-radius: 20px;
    }
    .renovationimg-col{
        margin-bottom: 2rem;
    }
}
@media only screen and (min-width: 320px) and (max-width: 575px) {
    .before{
    font-size: 16px;
    margin-right: 20px;
    padding: 10px 20px 0px 20px;
    }
    .after{
        font-size: 16px;
    }
 .afterbefore-img{
    margin-top: -1.8rem;
 }
 .click-text{
    font-size: 5vw;
 }
 }
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .click-text{
        font-size: 20px;
     }
   .featuresRow2{
    font-size: 25px;
   }
   .featuresRow2{
    padding-right: 30px;
    padding-left: 50px;
}
.before{
    font-size: 20px;
    }
.after{
    font-size: 20px;
}
.afterbefore-img{
    margin-top: -1.8rem;
 }
 .features-text{
    margin-left: -64px;
 }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .features-text{
        margin-left: -64px;
     }
    .click-text{
        font-size: 25px;
        margin-top: -4rem;
     }
    .featuresRow2{
     font-size: 25px;
    }
    .featuresRow2{
     padding-right: 30px;
     padding-left: 50px;
 }
 }
 @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .featuresRow2{
     font-size: 12px;
     padding-bottom: 2rem;
    }
    .renoGuide-img{
        width: 82%;
        margin-top: -0.5rem;
    }
 }
 @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .featuresRow2{
     font-size: 15px;
     padding-bottom: 2rem;
    }
    .renoGuide-img{
        width: 75%;
        margin-top: -0.5rem;
    }
    .click-text{
        padding: 3rem 55px 12px 55px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
 }
 @media only screen and (min-width: 1400px){
    .featuresRow2{
     font-size: 18px;
     padding-bottom: 2rem;
    }
    .renoGuide-img{
        width: 70%;
        margin-top: -1rem;
    }
    .click-text{
        padding: 3rem 75px 12px 75px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
 }